import React from 'react';
import styled from 'styled-components';

import { useI18n } from 'services/i18n';
import SEO from 'components/seo';
import Layout from 'components/layout';
import { Container, Row, Col } from 'styles/grid';
import { PrimaryTitle } from 'components/texts';

const DownloadLinkContainer = styled.div`
  text-align: center;
  margin: 4em auto;
  a {
    font-size: 1rem;
    display: inline-block;
    cursor: pointer;
    line-height: 1.5rem;
    color: #181818;
    text-decoration: none;
    :hover {
      color: #00a1ab;
    }
  }
`;

const FR_LINK = 'https://s3.eu-west-1.amazonaws.com/static.fitle.com/terms-and-conditions/fr/CGV-Fitle-de-base-2023.pdf';
const EN_LINK = 'https://s3-eu-west-1.amazonaws.com/static.fitle.com/website-cgv/cgv_fitle_en.pdf';

const Terms = () => {
  const [t, language] = useI18n();
  return (
    <Layout>
      <SEO title={t('seo.title.terms')} lang={language} description={t('seo.description.terms')} />
      <section>
        <Container>
          <Row justify="center">
            <Col sm={12} md={10} style={{ textAlign: 'justify' }}>
              <PrimaryTitle style={{ textAlign: 'center', marginBottom: 100 }}>
                {t('terms.title')}
              </PrimaryTitle>
              <DownloadLinkContainer>
                <a href={language === 'fr' ? FR_LINK : EN_LINK} target="blank" rel="noreferrer">
                  {t('terms.download')}
                </a>
              </DownloadLinkContainer>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Terms;
